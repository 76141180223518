<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-text-field
      v-model="form.title"
      :rules="MIXINS_REQUIRED_RULES"
      label="Title"
      dense
      filled
      class="rounded-lg"
    />
    <v-text-field
      v-model="form.pastor_name"
      :rules="MIXINS_REQUIRED_RULES"
      label="Facilitator"
      dense
      filled
      class="rounded-lg"
    />
    <!-- <v-select
      v-model="form.location_id"
      :rules="MIXINS_REQUIRED_RULES"
      :items="items"
      item-text="name"
      item-value="id"
      label="Location"
      dense
      filled
      class="rounded-lg"
    ></v-select> -->
    <location-select-box
      v-model="form.location_id"
      :rules="MIXINS_REQUIRED_RULES"
      item-text="name"
      item-value="id"
      label="Location"
      dense
      filled
      class="rounded-lg"
      @setDefaultLocationValue="form.location_id = $event"
    >
    </location-select-box>
    <!-- <div> -->
    <!-- <span class="font-weight-bold text-color-title">
        When would you like to publish this?
      </span> -->
    <!-- <div class="mt-4">
        <v-date-picker
          v-model="form.date"
          full-width
          class="date-picker-no-header"
        ></v-date-picker>
      </div> -->
    <!-- <div>
        <v-menu
          ref="menu"
          v-model="timePicker"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="form.time"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="form.time"
              label="Select time"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              filled
              class="rounded-lg"
              :rules="MIXINS_REQUIRED_RULES"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="timePicker"
            v-model="form.time"
            full-width
            @click:minute="$refs.menu.save(form.time)"
          ></v-time-picker>
        </v-menu>
      </div> -->
    <!-- </div> -->
    <div class="d-flex">
      <div>
        <v-menu
          v-model="startDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="form.start_date"
              label="Start Date"
              readonly
              v-bind="attrs"
              v-on="on"
              filled
              dense
              :rules="MIXINS_REQUIRED_RULES"
              class="mr-2 rounded-lg"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="form.start_date"
            @input="startDateMenu = false"
            :min="new Date().toISOString().substr(0, 10)"
          ></v-date-picker>
        </v-menu>
      </div>
      <v-spacer></v-spacer>
      <div>
        <v-menu
          ref="menuStartTime"
          v-model="startTimeMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="form.start_time"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="form.start_time"
              label="Start Time"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              filled
              class="rounded-lg"
              :rules="MIXINS_REQUIRED_RULES"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="startTimeMenu"
            v-model="form.start_time"
            full-width
            @click:minute="$refs.menuStartTime.save(form.start_time)"
          ></v-time-picker>
        </v-menu>
      </div>
    </div>
    <div class="d-flex">
      <div>
        <v-menu
          v-model="endDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="form.end_date"
              label="End Date"
              readonly
              v-bind="attrs"
              v-on="on"
              filled
              dense
              :rules="MIXINS_REQUIRED_RULES"
              class="mr-2 rounded-lg"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="form.end_date"
            @input="endDateMenu = false"
            :min="form.start_date"
          ></v-date-picker>
        </v-menu>
      </div>
      <v-spacer></v-spacer>
      <div>
        <v-menu
          ref="menuEndTime"
          v-model="endTimeMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          :return-value.sync="form.end_time"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="form.end_time"
              label="End Time"
              readonly
              v-bind="attrs"
              v-on="on"
              dense
              filled
              class="rounded-lg"
              :rules="MIXINS_REQUIRED_RULES"
            ></v-text-field>
          </template>
          <v-time-picker
            v-if="endTimeMenu"
            v-model="form.end_time"
            full-width
            @click:minute="$refs.menuEndTime.save(form.end_time)"
          ></v-time-picker>
        </v-menu>
      </div>
    </div>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    // timePicker: false,
    items: [],
    startDateMenu: false,
    endDateMenu: false,
    startTimeMenu: false,
    endTimeMenu: false
  }),
  props: {
    /**
     * Collection of data that will be supplied to this component
     */
    form: {
      type: Object,
      default: () => {
        return {};
      }
    },
    itemId: {
      type: Number,
      default: null
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    }
  }
};
</script>
